.project_card {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    row-gap: 2vh;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 56rem;
    transition: box-shadow 0.3s ease;
    cursor: pointer;

    @media (max-width: 1200px) {
        height: 30rem;
      }
    /* height: 100%;  */
}

.project_card>img {
    /* border-radius: 12px 12px 0 0; */
    width: 100%;
    height: 100%;
    object-fit: cover;

  
}
.project_card>p{
    display: flex;
    z-index: 2;
    position: absolute;
    top: 40%;
    min-height: 25%;
    align-items: center;
    /* left: 0; */
    width: 90%;
    color: #fff;
    text-align: center;
    font-size: 2.4rem;
    border-bottom: #fff solid 2px;
    border-top: #fff solid 2px;

    @media (max-width: 1200px) {
        font-size: 16px;
      }
}

.project_card::after {
    /* z-index: 1; */
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* background: linear-gradient(-180deg, transparent, rgba(0, 0, 0, 0.4)); */

    /* z-index: -1; */
}

.project_card:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.9);
}

@media (max-width:768px) {
    .project_card{
        width: 90%;
    height: 32rem;
    }
    
    .project_card>p{
        font-size: 16px;
    }
    
}

.main_wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 5%;

  /* width: 100%; */
}

.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main_info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1% 5%;
  color: rgb(78, 80, 82);
  font-weight: 400;
  font-size: 2.3vh;
  line-height: 1.4;
  justify-content: center;
  row-gap: 3rem;
}

.greeting_box {
  display: flex;
  align-self: center;
  font-size: 3.5vh;
  flex-direction: column;
  align-items: center;
}

.title {
  /* font-family: 'Montserrat', sans-serif; */
  font-weight: 700;
  font-size: 3rem;
  color: #b89654;
}
.greeting_text {
  width: 80%;
  text-align: justify;
  line-height: 1.7;
  font-size: 2.5vh;
  text-indent: 2em;
  display: flex;
  flex-direction: column;
  row-gap: 1vh;

  @media (max-width: 1200px) {
    font-size: 24px;
    width: 100%;
  }

  @media (max-width: 1000px) {
    font-size: 16px;
    line-height: 1.6;
    width: 100%;
  }

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 1.4;
    width: 100%;
  }
}
/* .btn {
    padding: 1% 3%;
    margin: 2% 0;
    border-radius: 3em;
    font-size: 15px;
    border: none;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    z-index: 1;
    background-color: #b89654;
    font-size: 1.2rem;
    color: aliceblue;
    transition: .5s ease;
   
}
.btn:hover {
 
    background-color: #e7ca91;
    box-shadow: 6px 6px 12px #c5c5c5;
}  */
.directions_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1vh;
  /* align-items: center; */
}
.directions_wrapper > p {
  padding-left: 5%;
  /* width: 90%; */
  /* align-self: center;
    text-align: center; */
  @media (max-width: 600px) {
    font-size: 18px;
  }
}
.directions {
  /* align-self: center; */
  font-weight: 800;
  font-size: 3vh;
  color: #065d43;
}
.dropdowns_wrapper {
  padding: 1% 0;
  width: 100%;
  align-self: center;
  display: flex;
  flex-direction: column;
  /* row-gap: 1vh; */
}
.recent_projects_blok {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.blok_title#projects {
  padding-top: 0;
  color: #043c2b;
}
.projects_cards {
  padding: 3% 0%;
  /* padding: 3% 5%; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
}

/* .container-fluid{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: 1rem;
} */
/* .btn::before{
    content: '';
    width: 0;
    height: 50px;
    border-radius: 15px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #e6c789 ;
    transition: .5s ease;
    display: block;
    z-index: -1;
}
.btn:hover::before{
    width: 100%;
    color:aliceblue,
} */

@media (max-width: 768px) {
  .projects_cards {
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    justify-items: center;
    align-items: center;
  }
}

.partners_blok {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: #b89654 solid 2px;
  padding-top: 2.7%;
}
.blok_title#partners {
  padding-top: 0;
  color: #043c2b;
}

.partners_cards {
    width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-bottom: 3%;
  /* gap: 3vh; */
}


.partners_card {
    display: flex;
    overflow: hidden;
  }

  .partners_card>img{
    object-fit: contain;
    height: 5vw;
    width: auto;
  }
  
.services_wrapper{
    display: flex;
    flex-direction: column;
    row-gap: 5%;
    padding: 1% 5%;
}

.services {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25%;
    width: 100%;
}
.price_wrapper{
    display: flex;
    flex-direction: column;
    /* padding-top: 3%; */
    font-size: 17px;
}
.blok_title{
    align-self: center;
    font-size: 22px;
    font-weight: 500;
    color: #b89654;
    padding: 2% 0;
    font-weight: 500;
}
.blok_title#diagnostic{
    color: #043c2b;
}
.diagnostic_wrapper{
    display: flex;
    flex-direction: column;
    row-gap: 5%;
    padding: 1% 5%;
}

.diagnostic_cards{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 3.2rem;
    @media (max-width: 1200px) {
        display: flex;
        flex-direction: column;
        /* gap: 10px; */
        /* grid-template-columns: 1fr; */
      }
}
.diagnostic_cards > :last-child {
    width: 50%;
    grid-column: 1 / span 2; 
    justify-self: center; 

    @media (max-width: 1200px) {
        width: 100%;
      }
}
.button_wrapper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15%;
}
.dropdown_wrapper {
    align-self: center;
    width: 90%;
    /* background-color: rgba(6, 93, 67, 0.1); */
    /* display: flex; */
    /* flex-direction: row;
    justify-content: space-between; */
}

.direction {
    min-height: 35px;
    cursor: pointer;
    padding: 0 20px;
    /* width: 90%; */
    background-color: rgba(6, 93, 67, 0.1);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.direction:hover{
    background-color: rgba(6, 93, 67, 0.2);
}

.direction>p {
    font-weight: 600;
    color: rgba(6, 93, 67 );
    font-size: 16px;
}

.direction_info{
    padding: 0 2%;
    background-color: rgba(6, 93, 67, 0.1);
    /* display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; */
}

.direction_list{
    padding: 1% 5% 2%;
    @media (max-width: 600px) {
        font-size: 16px;
        padding: 10px 10px 10px 25px;
      }
}


.direction_arrow{
    height: 40px;

}
.card {
    overflow: hidden;
    height: 56rem;
    max-width: 426px;
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 2vh;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    transition: box-shadow 0.3s ease;
    cursor: pointer;
}

.card>img {
    /* height: 56vh; */
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* width: 24vw; */
    border-radius: 12px;
}


.card::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(-180deg, transparent, rgba(0, 0, 0, 0.65));
    border-radius: 12px;
    /* z-index: -1; */
}

.card:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
}

.card_info {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 90%;
    bottom: 10%;
    z-index: 1;
    row-gap: 1vh;
}
.name{
    font-weight: 500;
    font-size: 3vh;
}

.post{
    font-size: 2.4vh;
    font-weight: 200;
}

.card_info>p {

    align-self: flex-start;
    color: #FFF;
   
 
    font-style: normal;
    line-height: 130%;
    letter-spacing: 0.48px;
   
    /* text-transform: uppercase; */
}


.overlay {
    background-color: 'rgba(0, 0, 0, 0.5)';
    z-index:10
}

  content{
    width: 400px;
    height: 200px;
    margin: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 20px;
  }
.feedback_wrapper{
    display: flex;
    flex-direction: column;
    row-gap: 1vh;
    padding: 1% 5%;
}
.reviews{
    display: flex;
    flex-direction: column;
    row-gap: 4vh;
    padding: 0% 5%;
}

/* .review_box{
  display: flex;
    flex-direction: column;
    row-gap: 5%;
    padding: 1% 5%;
}  */


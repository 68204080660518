.carousel_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.carousel .flickity-viewport {
    height: 48rem !important;
}

.carousel-cell {

    cursor: pointer;
    border-radius: 12px;
    width: 31%;
    margin-right: 3.3rem;
    transition: width 0.3s ease, height 0.3s ease, box-shadow 0.3s ease;
    height: 48rem;
    /* width: 100%;
    height: 300px;
    background-color: brown; */
}

/* .carousel-cell ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(-180deg, transparent, rgba(0, 0, 0, 0.65));
    border-radius: 12px;
   
} */

.carousel-cell.is-selected {
    height: 48rem;

}

.carousel-cell:not(.is-selected) {
    opacity: 0.7;
    width: 31%;
    height: 40rem;
}

.carousel-cell:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.9);
}
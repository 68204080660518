.table-line{
    display: grid;
    min-height: 7.2vh;
    /* grid-template-rows: 7.2vh; */
    grid-template-columns: 50% 25% 25%;
    background-color: rgba(6, 93, 67, 0.2);
}
.table-line-odd{
    display: grid;
    min-height: 7.2vh;
    /* grid-template-rows: 7.2vh; */
    grid-template-columns: 50% 25% 25%;
    background-color: rgba(6, 93, 67, 0.1);
}
.table-cell{
    padding: 2% 5%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.table-line:hover{
    background-color: rgba(6, 93, 67, 0.3);
}

.table-line-odd:hover{
    background-color: rgba(6, 93, 67, 0.3);
}
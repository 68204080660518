.navbar {
    position: relative;
    display: flex;
    background-color: #043c2b;
    align-items: center;
    justify-content: center;
    min-height: 60px;
}

.navbar-container {
    position: relative;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.nav-links {
    width: 100%;
    list-style: none;
    display: flex;
    flex-direction: row;
    column-gap: 3%;
}

.nav-links a {
    text-decoration: none;
    color: #fff;
    transition: color 0.3s;
    font-size: 20px;


    @media (max-width: 1200px) {
        font-size: 16px;
      }
    /* font-family: 'Montserrat', sans-serif; */
}

.nav-links a:hover {
    color: #e7ca91;
}

.nav-links>li {
    position: relative;
    display: block;
    transition: 0.5s;
    cursor: pointer;
}

.nav-links>li::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 2px;
    top: 100%;
    left: 0;
    transition: transform 0.5s;
    transform: scaleX(0);
    transform-origin: left;
    background-color: #e7ca91;
}

.nav-links>li:hover {
    color: #fff;
}

.nav-links>li:hover::after {
    transform: scaleX(1);
}

.active a {
    color: #fff;

}

#sidebar a.active {
    color: #e7ca91;
}

#sidebar a.active {
    color: red;
}

.ham {
    display: none;
}

/* .nav-links-open {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #043c2b;
    padding: 20px;
  }
  
  .nav-links-open li {
    margin-bottom: 15px;
  }
  
  .nav-links-open a {
    color: #fff;
    font-size: 18px;
    text-decoration: none;
    transition: color 0.3s;
  }
   */
/* .nav-links-open a:hover {
    color: #e7ca91;
  }
  
  .nav-links-open .active a {
    color: #e7ca91;
    font-weight: bold;
  } */

/* @media (max-width:1200px) {
    .nav-links a {
        font-size: 2vw;
    }
} */

@media (max-width:768px) {
  
    .ham {
        display: block;
        position: absolute;
        right: 5%;
        top: 10px;
    }

    .nav-links {
        display: none;
    }

    .nav-links-open {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: #043c2b;
        padding: 20px;
        transition: max-height 0.3s ease, opacity 0.3s ease;
    }

    .nav-links-open.nav-links-open-transition {
        max-height: 500px;
        opacity: 1;
    }

    .nav-links-open li {
        margin-bottom: 15px;
    }

    .nav-links-open a {
        color: #fff;
        font-size: 18px;
        text-decoration: none;
        transition: color 0.3s;
    }

    .nav-links-open a:hover {
        color: #e7ca91;
    }

    .nav-links-open .active a {
        color: #e7ca91;
        font-weight: bold;
    }


}
.footer_container {
    /* background-color: aliceblue; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1% 0;
    border-top: #b89654 solid 2px;

}

.accommodation {

    /* font-family: 'Montserrat', sans-serif; */
    font-weight: 800;
    color: #043c2b;
    font-size: 2.7vh;
    

}

.info_text{
    /* height: auto; */
    color: rgb(78, 80, 82);
    /* font-family: 'Montserrat', sans-serif; */
    font-weight: 400;
    font-size: 18px;
    line-height: 1.4;

}
.left_footer_parth {
    /* width: 50%; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 3vh;
}
.info_box{
    display: flex;
    flex-direction: column;
    row-gap: 1vh;
    align-items: flex-start;
    justify-content: center;

}

.right_footer_parth {
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 4.8rem;
    /* width: 80%; */
    justify-content: flex-end;
}

.footer_title{
    font-weight: 500;
    color: #043c2b;
    font-size: 20px;
}

@media (max-width:768px) { 
    .footer{
        padding: 3vh 3vh;
        flex-direction: column;
        row-gap: 3vh;
        align-items: center;
    }

    .right_footer_parth{
        width: 100%;
        justify-content: center;
    }
 

    .left_footer_parth{
        align-items: center;
    }
    .info_box {
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .info_text {
        width: 100%;
        text-align: center;
    }
    .doc_link{
        text-align: center;
    }
}
.info_small-text{
    font-size: 10px;
}

.payment_logos{
    width: 80%;
}
.slider_wrapper {
  margin-bottom: 3rem;
  position: relative;
}

.slider {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 59vh;
  width: 100%;
  overflow: hidden;
  position: relative;
  /* flex-shrink: 0; */
}

.slider_cover {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  /* backdrop-filter: blur(2px); */
}
.slider > img {
  /* height: 59vh; */
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.banner_wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  bottom: 0;
  /* background-color: red; */
}
.banner {
  height: 35rem;
  width: 40%;
  /* background-color: rgba(184, 150, 84, 0.9); */
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  backdrop-filter: blur(6px);
}


/* @media (max-width:768px) { 
    .slider{
        height: 30vh;
    }
    .main_slider .flickity-button {
        display: none;
    }

} */
/* 
.carousel .flickity-viewport {
    height: 30rem !important;
} */

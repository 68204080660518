.cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 3% 0;
    padding: 0 5%;
    width: 100%;
    align-self: center;
}
.box{
    display: flex;
    align-items: center;
    justify-content: center;
}

.bsdvvody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2%;
}
.cards-wrapper{
    padding: 3% 0%;
    /* padding: 3% 5%; */
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 4.8rem;

    /* display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 4.5vh;
    column-gap: 4.5vh; */
}


.infsdvdvo-wrapper {
    width: 82%;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    row-gap: 1vw;
    column-gap: 1vw;
    margin-bottom: 3%;

}

@media (max-width:768px) {
    .cards-wrapper{
        grid-template-columns: repeat(1,1fr);
        gap: 1.6rem;
    }
}
/* .modal_wrapper{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0,5);

}
.modal{

} */
.projects_wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 2vh;

    /* width: 100%; */
}

.project_header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 40rem;
}

.project_header>p {
    z-index: 1;
    position: absolute;
    color: #fff;
    text-align: center;
    font-size: 4rem;

    width: 90%;
}

.project_header>p::after {
    content: "";
    display: block;
    width: 50%;
    height: 3px;
    background-color: #fff;
    margin: 2% auto;
}

.project_header>img {
    width: 100%;
    height: 100%;
    object-fit: cover;

}

.project_header::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

}

.projects_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3% 7%;
    color: rgb(78, 80, 82);
    font-weight: 400;
    font-size: 2.3vh;
    line-height: 1.4;
    justify-content: center;
    row-gap: 2vh;
    text-align: justify;

}
.icons {
    display: flex;
    flex-direction: row;
    column-gap: 0.8rem;
}

.icon {
    cursor: pointer;
    color: #043c2b,
}

.icon:hover {
    color: #b89654
}
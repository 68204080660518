.map {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34vw;
    height: 20vw;
    border: none; 
    outline: none; 
}

@media (max-width:768px) { 
    .map{
        width: 100%;
        height: 101vw;
    } 
}
       

/* .map{
    width: 100%;
    height: 101vw;
} */
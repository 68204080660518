.d_card {
    border-radius: 12px 12px 0 0;
    /* height: 500px; */
    /* max-width: 426px; */
    width: 100%;
    /* position: relative; */
    display: flex;
    flex-direction: column;
    row-gap: 2vh;
    justify-content: flex-start;
    align-items: center;
    /* border-radius: 12px; */
    /* background-color: rgba(6, 93, 67, 0.4); */
    /* transition: box-shadow 0.3s ease; */
    /* cursor: pointer;; */
}

.d_header {
    border-radius: 12px 12px 0 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 20%; */
    /* background-color: rgba(6, 93, 67, 0.8); */
    min-height: 20%;
    border-bottom: solid #b89654;
}

.d_header>h3 {
    font-size: 2vh;
    padding: 0 3%;
    color: #b89654;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
}

.d_body {
    display: flex;
    width: 90%;
    flex-direction: column;
    row-gap: 1vh;
    /* height: 100%; */
    padding-bottom: 10%;
    /* background-color: black; */
}

.d_types {
    font-size: 1.6rem;
    font-weight: 500;
}

.d_types>li {
    list-style: none;
    line-height: 1.4;
    margin-bottom: 10px;
}

.d_types>li::before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    /* background-color: #043c2b;  */
    background-color: #206230;
    margin-right: 3%;
}

.d_descr {
    font-size: 1.6rem;
   
}
.certficate_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.certficate_cell {
    height: 17.6rem;
    width: 14.4rem;
    border: 2px solid #043c2b;
    background-color: gray;
    margin-right: 1.6rem;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.certficate_cell>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

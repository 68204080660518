.modal_header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.close_icon {
    position: absolute;
    position: absolute;
    right: 3%;
    top: 4%;
    cursor: pointer;
}

.modаl_body {
    display: flex;
    flex-direction: row;
    column-gap: 4vw;
}

.image_box {
    flex-wrap: 1;
    width: 35%;
    height: 25vw;
    overflow: hidden;
    /* margin-bottom: 3%; */
    margin-left: 3%;
}

.image_box>img {
    width: 100%;
}

.specifications {
    flex: 1.5;
    font-size: 2vh;
    display: flex;
    flex-direction: column;
    row-gap: 1vh;
    margin-left: 3%;
}

.work_areas {
    font-size: 2vh;
    display: flex;
    flex-direction: column;
    row-gap: 1vh;
    margin-left: 3%;
    line-height: 1.4;
}
h3#work_areas_title{
    padding-top: 3%;
}

/* .work_areas>li{
    margin-left: 3%; 
    line-height: 1.4;
} */
.work_areas>li::marker {
    font-size: 2vh;
    font-weight: 600;
}

h3 {
    font-size: 3vh;
    color: #b89654;
}

::-webkit-scrollbar {
    display: none;
}

.overlay {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10
}
.content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 70%;
    margin: auto;
 
    border-radius: 11px;
    padding: '3%',

}

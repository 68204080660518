.contact_wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5%;
    row-gap: 3vh;
}

.contact_us {
    /* font-family: 'Montserrat', sans-serif; */
    font-weight: 700;
    font-size: 5vh;
    color: #b89654;
}

.contacts_info{
    display: flex;
    flex-direction: column;
    row-gap: 2vh;
    font-size: 3vh;
    align-items: center;
}
.contact_links{
    align-self: center;
}
.problems_wrapper {
  width: 100%;
  gap: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.problems_table_wrapper {
  /* /* width: 100%; */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  grid-gap: 20px;
  row-gap: 4.8rem;
  column-gap: 3.2rem;
  width: 100%;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
  }

  @media (max-width: 600px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 10px;
    column-gap: 10px;
  }
}

.grid-item {
  background-color: #f2f2f2;
  padding: 10px;
}


body::-webkit-scrollbar {
  display: none;
}
:root {
  font-size: 10px;
}

* {
  word-wrap: break-word;
}

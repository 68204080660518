.btn {
    padding: 1% 3%;
    /* width: 24%; */
    margin: 2% 0;
    /* height: 50px; */
    border-radius: 3em;
    font-size: 15px;
    border: none;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    z-index: 1;
    background-color: #b89654;
    font-size: 1.92rem;
    color: aliceblue;
    transition: .5s ease;
   
}
.btn:hover {
    /* width: 100%; */
    background-color: #e7ca91;
    box-shadow: 6px 6px 12px #c5c5c5;
} 
.problem_card {
  width: 20rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  cursor: pointer;
  @media (max-width: 600px) {
    width: 12rem;
  }
}
.problem_card_icon {
  height: 18rem;
  width: 18rem;
  overflow: hidden;
  border-radius: 50%;

  @media (max-width: 1200px) {
    height: 15rem;
    width: 15rem;
  }

  @media (max-width: 600px) {
    height: 10rem;
    width: 10rem;
  }
}

.problem_icon {
  width: 100%;
  height: 100%;
  object-fit: fill;
  /* border-radius: 50%;
   height: 20rem; */

}

.problem_card > p {
  font-size: 2rem;
  text-align: center;
  color: #043c2b;
  font-weight: 800;
  @media (max-width: 600px) {
    font-size: 12px;
  }
}

.service_card{
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    row-gap: 2vh;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    transition: box-shadow 0.3s ease;
    cursor: pointer;
    border-radius: 12px;
     
}

.service_card>img {
    /* border-radius: 12px 12px 0 0; */
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
}
.service_card>p{
    display: flex;
    z-index: 2;
    position: absolute;
    top: 40%;
    min-height: 25%;
    align-items: center;
    justify-content: center;
    /* left: 0; */
    width: 90%;
    color: #fff;
    text-align: center;
    font-size: 2.4rem;
    border-bottom: #fff solid 2px;
    border-top: #fff solid 2px;
}

.service_card-cover{
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}

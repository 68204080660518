@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');

.wrapper {
    width: 100%;
    position: fixed;
}

.header_container {
    display: flex;
    align-items: center;
    justify-content: center;

}

.header {
    width: 97%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    margin-bottom: 1rem;
    border-bottom: #b89654 solid 2px;
    /* background-color: rgb(1, 1, 1, 0.5); */
}

.left_parth {
    display: grid;
    grid-template-columns: 6rem 1fr;
    align-items: center;

    /* column-gap: ; */

}

.right_part {
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 3rem;
    width: 80%;
    justify-content: flex-end;
}

.logo {
    width: 6rem;

}

.text-box {
    display: flex;
    flex-direction: column;

}

.header_title {
    font-weight: 700;
    color: #043c2b;
    font-size: 2rem;
}

.org {
    font-size: 1.4rem;
    width: 90%;
}



.contacts {
    display: flex;
    flex-direction: column;


}

.contacts>p {
    height: auto;
    color: rgb(78, 80, 82);
    /* font-family: 'Montserrat', sans-serif; */
    font-weight: 400;
    font-size: 1.6rem;
    font-weight: 600;
}

@media (max-width:768px) { 
    .right_part{
        display: none;
    }

    .left_parth {
        width: 100%;
        justify-content: space-between;
    }
}
.psycho_carousel_card_wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-right: 3.2rem;
  width: 40rem;
  /* width: 23rem; */
}
.psycho_carousel_card_image {
  height: 39rem;
  width: 40rem;
  border-radius: 16px;
  overflow: hidden;
  transition: 0.3s;
  /* background-color: red; */
}
.psycho_carousel_card_image > img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.psycho_carousel_card_image:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
}

.swiper {
  width: 100%;
  height: 100%;
}

@media ((min-width: 1000px) and (max-width: 1900px)) {
  .psycho_carousel_card_wrapper,
  .psycho_carousel_card_image {
    width: 30rem;
  }
}
.psycho_carousel_card_name{
    font-size: 20px;
    font-weight: 600;
}
.psycho_carousel_card_post{
    padding-top: 0.5rem;
    font-size: 16px;
    font-weight: 500;
}
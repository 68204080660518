.review_card_wrapper{
  
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    column-gap: 2vh
}

.mail{
    align-self: flex-start;
    width: 90%;
    color: #043c2b;
    font-weight: 600;
    

}
.user_image_box{
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: 1vh;
    align-items: center;
    justify-content: center;
    /* overflow: hidden; */
}

.image{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    /* width: 100%;
    height: 100%; */
    /* background-color: gray; */
}
.image>img{
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: #b89654 solid 3px;
}

.text{
    position: relative;
    flex: 10;
    background-color: rgba(6, 93, 67, 0.1);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1% 3%;
    row-gap: 0.5vh;
    border-radius: 15px;
}
.date{
    /* color: #043c2b; */
    color: #b89654;
    font-weight: 600;
    text-decoration: underline;
    
}
.text>p{
    /* width: 90%; */
    text-align: justify;
    font-size: 1.44rem;
    line-height: 1.4;
}

.text::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-100%, -50%); 
    width: 0;
    height: 0;
    border-top: 10px solid transparent; 
    border-bottom: 10px solid transparent;
    border-right: 10px solid  rgba(6, 93, 67, 0.1);
  }

